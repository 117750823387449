










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LegalInformationView extends Vue {
    /**
     * The legal information content
     */
    public content: HTMLOrSVGElement | null = null;
    /**
     *  A flag that shows a hint to an user if the requested language is not available
     */
    public langNotSupported: boolean = false;

    /**
     * Content url which extends the assets/legalInformation/ path to a language respecting content file
     */
    @Prop()
    public contentUrl!: string;

    public created() {
        this.loadContent();
    }

    /**
     * Function to load legal information content according requested langauge and passed content url
     */
    private async loadContent() {
        let lang = this.$route.params.lang;
        // if no language was defined, use current lang
        if (!lang) {
            lang = this.$i18n.locale;
        }
        let imported;
        try {
            // @ts-ignore
            imported = await import(
                /* webpackChunkName: "LegalInformationContent" */
                `!!raw-loader!@/assets/legalInformation/${this.contentUrl}/${lang}.html`);
        } catch (e: any) {
            // if some error occurs, just use the german version
            // RN-378 change VUE_APP_I18N_FALLBACK_LOCALE value to en as soon as all translation for en are available
            // @ts-ignore
            imported = await import(
                /* webpackChunkName: "LegalInformationContent" */
                `!!raw-loader!@/assets/legalInformation/${this.contentUrl}/${process.env.VUE_APP_I18N_FALLBACK_LOCALE}.html`);
            this.langNotSupported = true;
        }
        this.content = imported.default;
    }
}
